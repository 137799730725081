import { Component, NgZone  } from '@angular/core';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { environment } from 'src/environments/environment';
import { LocalStoreService } from './services/localstore.service';
import { DOMAIN_URL, fetchTestUrl } from './common/api';
import { DisableRightClickService } from './services/disable-right-click.service';
import { UtilityService } from './services/utility.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  alertID = "app-alert";
  internetCheck:any;
  errorCount:number = 0;
  counter = 0;
 intervalId: any;
  constructor(private store:LocalStoreService, private rightClickDisable: DisableRightClickService, private utilityService: UtilityService, private zone: NgZone) {
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);
    this.checkForNetwork();
    this.rightClickDisable.disableRightClick();

  }
  checkForNetwork() {
    
    console.log('checkForNetwork called')
    window.addEventListener('offline', () => {
      //Do task when no internet connection
      console.log('window is offline')

      });
      window.addEventListener('online', () => {
      //Do task when internet connection returns
        console.log('window is online')
      });
  }

  ngOnInit() {
    this.startCounter();
  }

  startCounter() {
    this.intervalId = setInterval(() => {
      this.zone.run(() => {
        this.counter++;
        console.log("this.counter",this.counter);
        this.verifyInternetConnectivity();
      });
    }, 3000);
  }


  async verifyInternetConnectivity() {
    const timeoutDuration = 3000; // Set timeout duration in milliseconds (e.g., 5000ms = 5 seconds)

    // Create a timeout promise
    const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Request timed out')), timeoutDuration)
    );

    try {
        // Ping a reliable URL and race it against the timeout
        const response = await Promise.race([
            fetch(fetchTestUrl, { method: 'HEAD', mode: 'no-cors' }),
            timeoutPromise
        ]);

        if (response) {
            console.log('Internet is working autologin'); 
            // clearInterval(this.intervalId);
            if (this.errorCount > 0) { 
                let Autologin = this.store.get('autoLoginUrl');
                this.store.hardlogout();
                location.href = DOMAIN_URL + Autologin;
            }
        }
    } catch (error) {
        this.errorCount++;
        let title = "Network Error";
        let msg = error.message === 'Request timed out' 
            ? "There’s a temporary issue with the internet. Please hold on while we restore the connection." 
            : "There’s a temporary issue with the internet. Please hold on while we restore the connection.";
        
        this.utilityService.showAlert(title, msg, this.alertID);

        this.internetCheck = setInterval(() => {
            const body = document.querySelector('ion-header');
            const contents = document.querySelectorAll('ion-content');
            const footers = document.querySelectorAll('ion-footer');
            if (body) {
                body.click(); // Trigger a click programmatically
            } else if (contents) {
              contents.forEach((content, index) => {
                content.addEventListener('click', () => {
                  console.log(`Content ${index + 1} dynamically clicked`);
                });
                content.dispatchEvent(new Event('click', { bubbles: true })); // Trigger programmatic click
              });
            } else if (footers) {
              footers.forEach((footer, index) => {
                footer.addEventListener('click', () => {
                  console.log(`Content ${index + 1} dynamically clicked`);
                });
                footer.dispatchEvent(new Event('click', { bubbles: true })); // Trigger programmatic click
              });
            }
        }, 3000);
        
        console.log('Internet is not working despite being home');
    }
  }

  closeModal(){
    this.utilityService.closeAlert(this.alertID);
  }

  ionViewDidLeave(){
    //clearInterval(this.intervalId);
  }
}
