import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { KioskapiService } from '../services/kioskapi.service';
import { DOMAIN_URL, fetchTestUrl } from '../common/api';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-kioskautologin',
  templateUrl: './kioskautologin.page.html',
  styleUrls: ['./kioskautologin.page.scss'],
})
export class KioskautologinPage implements OnInit {
  id:any;
  uuid:any;
  internetinterval:any
  errorCount =0;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(
      private httpClient: HttpClient, 
      private router: Router,
      private kioskauthservice:KioskauthService,
      private route: ActivatedRoute,
      private store:LocalStoreService,
      private kioskApiServices: KioskapiService,
      private alertController: AlertController
    ) { 

      
  }

  ionViewWillEnter() {

    this.internetinterval = setInterval(()=>{
      this.verifyInternetConnectivity();
    }, 3000);

    this.id = this.route.snapshot.paramMap.get('id');
      this.uuid = this.route.snapshot.paramMap.get('uuid');
      let tid  =  this.route.snapshot.paramMap.get('tid');
      this.store.set('terminalid', tid);
      // let payload = {"user_information_uuid": this.id, "user_hmac": this.uuid, "restaurant_type": "CHG"};    
      let payload = {"user_information_uuid": this.id, "user_hmac": this.uuid};    
      let kioskAutoLoginUrl = this.router.url;
      this.store.set('autoLoginUrl', kioskAutoLoginUrl);
      console.log('kioskAutoLoginUrl', kioskAutoLoginUrl)
      console.log('this.router.url', this.router.url)
      this.kioskauthservice
      .apiReq('post', 'auth/elologin', payload)
      // .apiReq('post','auth/update',this.nickForm.value)
      .subscribe((response) => {
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          // this.utilityService.onSuccess(response.message);
          let data = response.aaData ;
          localStorage.setItem("master_restaurant_uuid",data.master_restaurant_uuid);
          localStorage.setItem("user_information_uuid",data.user_information_uuid);
          let params = {
            master_restaurant_uuid: data.master_restaurant_uuid
          };   

          this.kioskauthservice
          .apiReq('post', 'masterrestaurant/getbyuuid', params)
          .subscribe((response) => {
            if(response.statusCode == 200) {
              this.store.set("user",data);
              this.store.set("restaurant",response.aaData);
              this.store.set("selectedCountry",data.country_detail);
              this.create_sessionId();
              if (!this.store.get("language")) {
                let language: any = {};
                    language['language_uuid'] = '';
                    language['language_code'] = data.language.master_language_code;
                this.store.set("language",language);
              }
              this.kioskApiServices.logEvents("AUTO_LOGIN");
              this.kioskApiServices.audit_log("AUTO_LOGIN");

              this.router.navigate(['/scan']);
            }
          })
        }
      });
  }

  public  process_autologin(API_URL, payload) {
     
    this.httpClient.post(API_URL, payload, this.httpOptions)
    .subscribe(data => {          
     }, error => {
    });
              
  }

  ngOnInit() {
    
    
  }

  create_sessionId() {
    const sessionId = Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5) + moment().format('HHMMSS');;
    this.store.set('sessionId', sessionId);
  }

  // Helper function to verify actual internet connectivity
  async verifyInternetConnectivity() {
    try {
        // Ping a reliable URL
        const response = await fetch(fetchTestUrl, { method: 'HEAD', mode: 'no-cors' });
        if (response) {
            console.log('Internet is working autologin'); 
            clearInterval(this.internetinterval);
          if(this.errorCount > 0){ 
            let Autologin = this.store.get('autoLoginUrl')
            this.store.hardlogout()
            location.href = DOMAIN_URL + Autologin;
          }
        }
    } catch (error) {
      this.errorCount++;
      this.technical_error();
        console.log('Internet is not working despite being autologin');
        // Handle scenarios where the internet is not working
    }
  }

  async technical_error(){
    const alert =  this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Oops!',//'Please Tap Again',
      message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
      buttons: [
      {
       text: '',
       cssClass: 'cross',
      handler: () => {
       this.alertController.dismiss();
       }
     }
      ]
      });

       (await alert).present();
    }
  
}